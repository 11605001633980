'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:CondensedReport
 * @description
 * # CondensedReport
 * Report resource of the diceApp
 */
angular.module('diceApp')
  .factory('CondensedReport', function (DiceResource) {
    return DiceResource('/condensed-reports');
  });
