'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:diceEvaluationGeneral
 * @description Shows the evaluation general page.
 */
angular.module('diceApp')
  .component('diceEvaluationGeneral', {
    bindings: {
      evaluation: '<',
      operations: '<'
    },
    templateUrl: 'es6/evaluations/evaluation.general.html',
    controllerAs: 'evaluationGeneralController',
    controller: function (authentication, errorFieldsStore, Evaluation, ReportTemplate, Workflow, message, $q, $state, $uibModal) {
      const evaluationGeneralController = this;

      evaluationGeneralController.$onInit = function() {
        evaluationGeneralController.userRole = authentication.getCurrent().role;
        evaluationGeneralController.editable = isGranted('EDIT_GENERAL');
        
        if (isGranted('REPORT') || isGranted('SHOW_PUBLIC_REPORT')) {
          ReportTemplate.query({
            evaluationType: evaluationGeneralController.evaluation.type.id,
            active: true
          }).$promise.then((templates) => {
            evaluationGeneralController.publicReports = _.filter(templates, { reportType: 'PUBLIC_REPORT' });
          });
        }

        setOriginal();
        loadData();
      };

      function isGranted(operation) {
        return evaluationGeneralController.operations[operation] === true;
      }

      function setOriginal() {
        evaluationGeneralController.original = compact(evaluationGeneralController.evaluation);
      }

      function compact(evaluation) {
        return _.pick(evaluation, ['startDate', 'reminderDate', 'endDate']);
      }

      function loadData() {
        evaluationGeneralController.loading = true;
        $q.all([
          Evaluation.progress({
            id: evaluationGeneralController.evaluation.id
          }).$promise,
          Workflow.dateTypes({
            evaluationTypeId: evaluationGeneralController.evaluation.type.id
          }).$promise
        ]).then(([progress, dates]) => {
          evaluationGeneralController.progress = progress;
          evaluationGeneralController.showReminderDate = _.includes(dates, 'EVALUATION_REMINDER');
          evaluationGeneralController.showEndDate = _.includes(dates, 'EVALUATION_END');
        }).finally(() => {
          evaluationGeneralController.loading = false;
        });
      }

      evaluationGeneralController.save = function (evaluation, form) {
        const defer = $q.defer();

        confirm(evaluation, () => {
          updateDates(evaluation);

          const body = {
            code: evaluation.code,
            names: evaluation.names,
            language: evaluation.language,
            startDate: evaluation.startDate,
            reminderDate: evaluation.reminderDate,
            endDate: evaluation.endDate,
            startTime: evaluation.startTime,
            tags: evaluation.tags
          };

          return Evaluation.saveGeneral({ id: evaluation.id }, body).$promise.then(() => {
            message.addSuccessLabel('Message.Save.Success');
            errorFieldsStore.clearErrors();
            form.$setPristine(false);
            defer.resolve();
            $state.reload();
          });
        }, () => {
          evaluationGeneralController.evaluation = _.extend(evaluationGeneralController.evaluation, evaluationGeneralController.original);
          defer.resolve();
        });

        return defer.promise;
      };

      function updateDates(evaluation) {
        if (angular.isUndefined(evaluation.reminderDate) || evaluation.reminderDate < evaluation.startDate) {
          evaluation.reminderDate = evaluation.startDate;
        }
        if (angular.isUndefined(evaluation.endDate) || evaluation.endDate < evaluation.reminderDate) {
          evaluation.endDate = evaluation.reminderDate;
        }
      }

      function confirm(evaluation, onConfirm, onCancel) {
        if (hasChanged(evaluation)) {
          $uibModal.open({
            size: 'lg',
            template: `
              <schedule-workflow-calculate-modal
                evaluation="evaluationConfirmController.evaluation"
                on-cancel="evaluationConfirmController.cancel()"
                on-submit="evaluationConfirmController.confirm()">
              </schedule-workflow-calculate-modal>
            `,
            controllerAs: 'evaluationConfirmController',
            controller: function($uibModalInstance) {
              const evaluationConfirmController = this;
              evaluationConfirmController.evaluation = angular.copy(evaluation);

              evaluationConfirmController.cancel = function() {
                onCancel();
                close();
              };

              evaluationConfirmController.confirm = function() {
                onConfirm();
                close();
              };

              function close() {
                $uibModalInstance.dismiss();
              }
            }
          });
        } else {
          onConfirm();
        }
      }

      function hasChanged(evaluation) {
        const current = compact(evaluation);
        return !_.isEqual(current, evaluationGeneralController.original);
      }

      evaluationGeneralController.archive = function() {
        evaluationGeneralController.loading = true;
        Evaluation.archive({
          evaluationId: evaluationGeneralController.evaluation.id
        }, {}).$promise.then((result) => {
          message.addSuccessLabel('Message.Archived');
          _.extend(evaluationGeneralController.evaluation, result);
        }).finally(function() {
          evaluationGeneralController.loading = false;
        });
      };

      evaluationGeneralController.calculate = function() {
        evaluationGeneralController.loading = true;
        Evaluation.calculate({
          evaluationId: evaluationGeneralController.evaluation.id
        }, {}).$promise.then((result) => {
          message.addSuccessLabel('Message.Save.Success');
          _.extend(evaluationGeneralController.evaluation, result);
        }).finally(function() {
          evaluationGeneralController.loading = false;
        });
      };

      evaluationGeneralController.showTimer = function() {
        $uibModal.open({
          template: `
            <dice-evaluation-timer
              evaluation="modalController.evaluation"
              on-close="modalController.close()">
            </dice-evaluation-timer>
          `,
          size: 'xl',
          controllerAs: 'modalController',
          controller: function ($uibModalInstance) {
            const modalController = this;
            modalController.evaluation = evaluationGeneralController.evaluation;
            modalController.operations = evaluationGeneralController.operations;

            modalController.close = function() {
              $uibModalInstance.close();
              loadData();
            };
          }
        });
      };
    }
  });
