'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:questionTemplateTypeahead
 * @description Select a question template.
 */
angular.module('diceApp')
  .component('questionTemplateTypeahead', {
    bindings: {
        evaluationTypeId: '<',
        isRequired: '<?',
        params: '<?'
    },
    templateUrl: 'es6/templates/question/question.template.typeahead.html',
    require: {
        ngModelCtrl: 'ngModel'
    },
    controllerAs: 'questionTypeaheadController',
    controller: function (QuestionTemplate, Pageable, $element, language, stripHtmlFilter) {
        const questionTypeaheadController = this;

        questionTypeaheadController.$onInit = function () {
          questionTypeaheadController.pageable = Pageable.of({
            order: 'label',
            pageSize: 5
          });

          questionTypeaheadController.ngModelCtrl.$validators.required = (modelValue) => {
            return questionTypeaheadController.isRequired !== true || _.isNumber(modelValue);
          };
    
          questionTypeaheadController.ngModelCtrl.$parsers.push((viewValue) => {
            if (_.isObject(viewValue)) {
              return _.get(viewValue, 'id');
            } else if (_.isNumber(viewValue)) {
              return viewValue;
            }
          });
    
          questionTypeaheadController.ngModelCtrl.$render = () => {
            if (questionTypeaheadController.ngModelCtrl.$viewValue) {
            QuestionTemplate.get({
                id: questionTypeaheadController.ngModelCtrl.$viewValue
              }).$promise.then((template) => {
                questionTypeaheadController.template = template;
              });
            } else {
              delete questionTypeaheadController.template;
            }
          };
        };
    
        questionTypeaheadController.getTemplates = function (text) {
          const params = _.extend(questionTypeaheadController.params, {
            evaluationType: questionTypeaheadController.evaluationTypeId,
            language: language.get(),
            text: _.lowerCase(text),
            page: 0,
            size: 10
          });
    
          questionTypeaheadController.loading = true;
          return QuestionTemplate.search(params).$promise.then((result) => {
              questionTypeaheadController.totalElements = result.totalElements;
              return _.map(result.content, stripHtml);
            }).finally(() => {
              questionTypeaheadController.loading = false;
            });
        };

        function stripHtml(template) {
          return _.extend(template, {
            label: stripHtmlFilter(template.label)
          });
        }
    
        questionTypeaheadController.setFocus = function () {
          $element.find('input')[0].focus();
        };
    
        questionTypeaheadController.onChange = function (value) {
          if (angular.isUndefined(value)) {
            delete questionTypeaheadController.template;
          }

          questionTypeaheadController.ngModelCtrl.$setViewValue(angular.copy(value));
        };
    }
});