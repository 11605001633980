'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:MemberReport
 * @description
 * # MemberReport
 * Report resource of the diceApp
 */
angular.module('diceApp')
  .factory('MemberReport', function (DiceResource) {
    return DiceResource('/member-reports', {
      evaluations: {
        method: 'GET',
        isArray: true,
        url: '/member-reports/:personId'
      }
    });
  });
