'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:authentication
 * @description
 * # authentication
 * authentication of the diceApp
 */
angular.module('diceApp')
  .factory('authentication', function ($http, $state, $window, jarbInputStore) {

    const ANONYMOUS = {};

    let listeners = [];
    let current = ANONYMOUS;

    return { getConfig, getCurrent, loadCurrent, isAuthenticated, isAdmin, hasRole, login, logout, become, restore, authenticateToken, subscribe };

    function getConfig() {
      return $http.get('/api/authentication/config').then((result) => result.data);
    }

    function getCurrent() {
      return angular.copy(current);
    }

    function loadCurrent() {
      return $http.get('/api/authentication').then((result) => {
        const authentication = result.data;
        setAuthentication(authentication);
        return authentication;
      });
    }

    function setAuthentication(authentication) {
      current = authentication;
      onChange();
    }

    function isAuthenticated() {
      return _.get(current, 'authenticated') === true;
    }

    function isAdmin() {
      return hasRole(['SYSTEM_ADMIN', 'ADMIN']);
    }

    function hasRole(expected) {
      if (!_.isArray(expected)) {
        expected = [expected];
      }
      return _.indexOf(expected, current.role) >= 0;
    }

    function login(username, password) {
      return $http.post('/api/authentication', {
        username: username,
        password: password
      }).then((result) => {
        const authentication = result.data;
        setAuthentication(authentication);
        return authentication;
      });
    }

    function logout() {
      return $http.delete('/api/authentication').then((result) => {
        setAuthentication(ANONYMOUS);

        const data = angular.fromJson(result.data);
        const redirectUrl = _.get(data, 'logoutUrl');
        if (redirectUrl) {
          $window.location.href = redirectUrl;
        } else {
          $state.go('login');
        }
      });
    }

    function become(person) {
      return $state.transitionTo('dashboard').then(() =>
        $http.post(`/api/login/impersonate/${person.id}`).then(reload)
      );
    }

    function restore() {
      return $state.transitionTo('dashboard').then(() =>
        $http.post('/api/logout/impersonate').then(reload)
      );
    }

    function authenticateToken(token) {
      return $http.post('/api/token/authenticate', {
        token,
        logoutUrl: '/#/token?action=logout'
      }).then((result) => result.data);
    }

    function subscribe(listener) {
      listeners.push(listener);
    }

    function onChange() {
      notify();

      if (jarbInputStore.getConstraints() === null && isAuthenticated()) {
        jarbInputStore.loadConstraints('api/constraints');
      }
    }

    function notify() {
      _.forEach(listeners, (listener) => {
        if (angular.isFunction(listener)) {
          listener();
        }
      });
    }

    /*
     * After login-as or restore we want
     * to completely reload the dashboard page
     * so that all settings are retrieved again
     * (academic year, etc.)
     *
     * The body is hidden so you don't see the
     * dashboard appearing for the 'old' user.
     */
    function reload() {
      angular.element('body').hide();
      $window.location.reload();
    }

  });
