'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:Report
 * @description
 * # Report
 * Report resource of the diceApp
 */
angular.module('diceApp')
  .factory('Report', function (DiceResource) {
    return DiceResource('/reports', {
      generate: {
        url: '/reports',
        isArray: true,
        method: 'POST'
      },
      templates: {
        url: '/reports/templates',
        isArray: true,
        method: 'GET'
      },
      latest: {
        url: '/reports/latest',
        method: 'GET'
      }
    });
  });
